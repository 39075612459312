import { navigate } from "gatsby";
import * as React from "react";
import type { PageProps } from "gatsby";
import { ProofPopup } from "components/ProofPopup";
import WistiaVideo from "components/WistiaVideo";
import { Wrapper, Modal, Urgency } from "components/MasterclassFunnel";
import { StaticImage } from "gatsby-plugin-image";
import { Heading1, Heading2, Heading3, Paragraph } from "components/MasterclassFunnel";
import Metadata, { IMetadataProps } from "components/Metadata";

export default ({ location }: PageProps) => {
  const meta: IMetadataProps = {
    pageTitle: "Los Secretos de las Celebrities",
    domain: "https://v2.drplanascosmetica.com/",
    url: "https://v2.drplanascosmetica.com/secretos/masterclass",
    title: "Los 3 Secretos Anti-Envejecimiento de las Celebrities",
    image: "https://i.pinimg.com/564x/f2/93/30/f29330332a9a367d6188457aa8e948a0.jpg",
    desc: "¡Al Descubierto! El Dr Planas desvela los secretos contra el envejecimiento que usan las top celebrities",
  };
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (sessionStorage.getItem("dpl-kl") && sessionStorage.getItem("dpl-kl") !== "undefined") {
      setLoading(false);
    } else {
      navigate("/secretos");
    }
  }, []);

  if (!loading) {
    return (
      <>
        <Metadata {...meta} />
        <section id="HERO" className="relative">
          <Wrapper className="">
            <div id="HEADER" className="text-gray-300 flex justify-center items-cente -mt-10 mb-10">
              <h1 className="uppercase text-sm pt-3 font-['Copperplate_Gothic']">
                Dr. Jorge Planas
              </h1>
              <DrPlanasLogo className="h-10 w-10" />
            </div>
            <div id="HERO">
              <StaticImage
                alt="Dr Planas en despacho"
                src="../../static/masterclass-hero-bg.jpg"
                className="top-0 left-0 h-full w-full z-[-2] brightness-[0.2]"
                style={{ position: "absolute" }}
              />
              <div className="absolute top-0 left-0 h-full w-full z-[-1] bg-[#1B4A93]/10" />

              <p className="mb-3 text-red-600 text-[14px] text-center font-bold leading-[20px] tracking-wider animate-blinkingText ">
                <span className="w-[12px] h-[12px] rounded-full bg-red-600 mr-2 inline-block " />
                AVISO: NO CIERRES LA PAGINA O PERDERAS TU PLAZA
              </p>

              <Heading1 className="text-gray-100">
                Los 3 Metodos Secretos de las Top Celebrities Para Combatir el Envejecimiento de
                Forma NATURAL y SIN Pasar por el Quirofano
              </Heading1>

              <p className="text-gray-200 space-y-4 text-[20px] leading-[34px] text-center mt-10 max-w-3xl mx-auto font-light">
                Dr. Jorge Planas revela los secretos que rejuvenecerán tu piel de 10 a 15 años,
                exclusivamente en esta masterclass
              </p>

              <ArrowSmDownIcon className="text-white mx-auto w-6 h-6" />

              <div className="relative mt-6 flex-1 w-full shadow-[0_0_20px_rgba(29,74,255,0.7)] hover:shadow-none transition-all rounded-md overflow-hidden aspect-video">
                <WistiaVideo scriptCode="5q8ytq1h22" />
              </div>

              <AppearingCTAButton />
            </div>
          </Wrapper>
        </section>
      </>
    );
  } else {
    return (
      <>
        <Metadata {...meta} />
        <section id="HERO" className="relative">
          <Wrapper className="">
            <div>Loading...</div>
          </Wrapper>
        </section>
      </>
    );
  }
};

const AppearingCTAButton = () => {
  const [showCTA, setShowCTA] = React.useState(false);
  const [buttonDim, setButtonDim] = React.useState({ width: 0, height: 0 });

  React.useEffect(() => {
    // Get Wistia video handler
    window._wq = window._wq || []; // make sure window._wq is defined as an array
    window._wq.push({
      // push a matcher associated with callback function onto the queue
      id: "5q8ytq1h22",
      onReady: function (video: any) {
        // @TODO: change time to when the offer happens
        video.bind("crosstime", 20 * 60, function () {
          setShowCTA(true);
        });

        // @TODO: set the analutics as % of video watched (with the seconds)
      },
    });
  }, []);

  React.useEffect(() => {
    const button = document.getElementById("appearing-cta");
    const width = button!.offsetWidth;
    const height = button!.offsetHeight;
    setButtonDim({ width: width, height: height });
  }, [showCTA]);
  return (
    <div className="relative mx-auto mt-4 mb-10 hover:scale-105 transition-scale duration-[600ms] max-w-full md:w-max px-5">
      <div className="relative rounded-md overflow-hidden">
        <a href="https://pack.drplanascosmetica.com/">
          <button
            // This button fades in once the border has been drawn
            id="appearing-cta"
            className={`btn btn-4 absolute top-0 text-white bg-[rgb(29,74,255)] px-7 py-5 text-center mx-auto rounded-md transition-opacity duration-[2000ms] delay-[2000ms] ${
              showCTA ? "opacity-100" : "opacity-0"
            }`}
            disabled={!showCTA}
            onClick={() => console.log("button clicked")}
          >
            <p className="text-xl md:text-3xl font-extrabold md:font-bold uppercase">
              Reclama tu descuento de 66€
            </p>
            <p className="text-xs md:text-sm font-base uppercase mt-1">
              ¡Se rapido! A dia de hoy (15 de junio), casi todos los paquetes de esta oferta estan
              agotados
            </p>
          </button>
        </a>

        {showCTA && (
          <svg
            className="absolute top-0 z-[-1]"
            width={buttonDim.width}
            height={buttonDim.height}
            viewBox={`0 0 ${buttonDim.width} ${buttonDim.height}`}
          >
            <g style={{ strokeDasharray: `${buttonDim.width * 2 + buttonDim.height * 2}` }}>
              <animate
                attributeName="stroke-dashoffset"
                dur="2000ms"
                values={`${buttonDim.width * 2 + buttonDim.height * 2}; 0`}
                keyTimes="0; 1"
                fill="freeze"
              />
              <rect
                width="100%"
                height="100%"
                style={{
                  fill: "transparent",
                  stroke: "rgb(29,74,255)",
                  strokeWidth: "8",
                }}
              />
            </g>
          </svg>
        )}
      </div>
    </div>
  );
};
// -------------------------------------------------------------------------------------------------
// ICONS & SVGs
// -------------------------------------------------------------------------------------------------
const DrPlanasLogo = ({ className = "" }) => (
  <svg className={className} viewBox="0 0 528 768" fill="none">
    <path
      d="M7.5 263.5C59.8333 208.333 174.7 101.4 217.5 131C271 168 93 622.5 96.5 702C99.7355 775.491 200.39 475.971 317.097 374.188M317.097 374.188C326.639 365.866 336.288 358.866 346 353.5M317.097 374.188C314.567 438.767 320.755 488.587 330.5 498.5C347.123 515.409 362 340.5 346 353.5M317.097 374.188C322.095 246.592 361.127 61.3756 474 12.5C562 -25.6052 474 213 355.5 320C346 328.578 341.5 346.5 346 353.5M150 759.5C163 757 207.009 748.948 242 723.5C275 699.5 401 613 426.5 607M511 334.5L520.5 327"
      stroke="currentColor"
      strokeWidth="15"
      strokeLinecap="round"
    />
  </svg>
);

const ArrowSmDownIcon = ({ className = "" }) => (
  <svg
    className={` ${className}`}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2"
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M17 13l-5 5m0 0l-5-5m5 5V6" />
  </svg>
);
